import React from "react";
import { Alert } from "react-bootstrap";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import rules_icon from "../../../images/icons/icon_rules_light.svg";

// Create ParkRules Section with conditionals
interface Props {
    parkTitle: string | null | undefined, 
    parkRules: string | null | undefined, 
    grammarAgreementOf: string | undefined
}
export default function ParkRules(props: Props) {
    const { parkTitle, parkRules, grammarAgreementOf } = props;

  if (parkRules) {
    return (
      <div className="pt-3">
        <Alert variant="warning">
          <div className="alert-warning-title">
            <div className="d-flex align-items-stretch">
              <img
                src={rules_icon}
                alt="Réglementation du parc naturel"
                className="icon d-inline-flex me-2"
              />
              <h2 className="d-inline-flex light-yellow">
                Réglementation {grammarAgreementOf}{" "}
                {parkTitle}
              </h2>
            </div>
          </div>
          <div className="alert-warning-content">
            <ReactMarkdown>{parkRules}</ReactMarkdown>
          </div>
        </Alert>
      </div>
    );
  }
  return null;
}
