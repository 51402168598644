import React from "react";
import AccommodationsCarousel from "../../Accommodations/AccommodationsCarousel";
import { Col, Row, Container } from "react-bootstrap";


interface Props {
    slug?: string | null,
    parkTitle: string | null | undefined,
    parkFullTitle: string | null | undefined,
    parkGrammarAgreementOf: string | null | undefined
}

export default function ParkAccommodations(props: Props) {
    const setCarouselSettings = {
      responsive: {
        breakpoint2400: {
          slidesToShow: 4.1,
        },
        breakpoint1500: {
          slidesToShow: 3.8,
        },
        breakpoint1300: {
          slidesToShow: 3.5,
        },
        breakpoint1024: {
          slidesToShow: 3.1,
        },
        breakpoint768: {
          slidesToShow: 2.1,
        },
        breakpoint480: {
          slidesToShow: 1.4,
        },
      },
      className: "center",
      centerPadding: "60px",
      slidesToShow: 4.2,
      slidesToScroll: 3,
      infinite: false,
    };
  
    return (
      <Col>
        <Container>
          <Row>
            <Col>
              <h2>Logements à proximité {props.parkGrammarAgreementOf} {props.parkTitle}</h2>
              <AccommodationsCarousel
                slug={props.slug}
                carouselSettings={setCarouselSettings}
              />
              <p>Retrouvez un grand nombre de logements, gîtes et locations au cœur {props.parkGrammarAgreementOf} {props.parkFullTitle} avec notre partenaire Abritel.</p>
            </Col>
          </Row>
        </Container>
      </Col>
    );
  }
  