import React from "react";
import ObfuscationLink from "../../../seo/ObfuscationLink";

import accomodations_icon from '../../../../images/icons/icon_accomodation.svg';

interface Props {
    bnbAffiliateLink: string | null | undefined,
    hotelAffiliateLink: string | null | undefined
}
export default function AffiliateLinks(props: Props) {
    const { bnbAffiliateLink, hotelAffiliateLink } = props;

        // SIDEBAR VARIABLE
    // If there is a booking appartment link : create a variable <i> obfuscated.
    const BnbAffiliateLink = () => {
        if(bnbAffiliateLink) {
            return (
                <li>
                    <ObfuscationLink encodeUrl={bnbAffiliateLink}>Louer un logement</ObfuscationLink>
                </li>
            )
        }
        return null
    }

    // If there is a booking hotel affiliate link : create a variable <i> obfuscated.
    const HotelsAffiliateLink = () => {
        if(hotelAffiliateLink) {
            return (
                <li>
                    <ObfuscationLink encodeUrl={hotelAffiliateLink}>Réserver un hôtel</ObfuscationLink>
                </li>
            )
        }
        return null
    }

  // If there is a Booking Hotel or Booking Appartment link, create the affiliate section
  if (HotelsAffiliateLink() || BnbAffiliateLink()) {
    return (
      <div className="sidebar-content-section">
        <div className="d-flex mb-2">
          <img
            src={accomodations_icon}
            alt="Trouver un hébergement pour son voyage"
            className="icon d-inline-flex me-2"
          />
          <h3 className="d-inline-flex align-items-center mb-0">Hébergement</h3>
        </div>
        <ul>
          <HotelsAffiliateLink />
          <BnbAffiliateLink />
        </ul>
      </div>
    );
  }
  return null;
};
