import React from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import wildlife_icon from '../../../../images/icons/icon_wildlife.svg';


interface Props {
    wildlife: string | null | undefined,
    grammarAgreementOf: string | null | undefined,
    parkTitle: string | null | undefined
}

// Create sidebar Wildlife section
export default function ParkWildlife(props: Props) {
  if (props.wildlife) {
    return (
      <div className="sidebar-section">
        <div className="sidebar-content-section">
          <div className="d-flex mb-2">
            <img
              src={wildlife_icon}
              alt="Quelle faune est présente dans ce parc"
              className="icon d-inline-flex me-2"
            />
            <h3 className="d-inline-flex align-items-center mb-0">
              Faune {props.grammarAgreementOf} {props.parkTitle}
            </h3>
          </div>
          <ReactMarkdown>{props.wildlife}</ReactMarkdown>
        </div>
      </div>
    );
  }
  return null;
};
