import React from "react";
import { useEffect, useState } from "react";
import { Link, graphql, HeadProps, PageProps } from "gatsby";
import Breadcrumb from "../components/Layout/breadcrumb";
import { Col, Row, Container } from "react-bootstrap";
import ReactMarkdown from "react-markdown";

import Meta from "../components/Layout/head";
import Layout from "../components/Layout/layout";
import ObfuscationLink from "../components/seo/ObfuscationLink";
import MapboxStaticImage from "../components/Mapbox/MapboxStaticImage";
import ParksCarousel from "../components/Park/ParksCarousel";
import ParkGallery from "../components/Park/park-gallery";
import validateURL from "../components/security/validate-url";
import GrammarAgreement from "../components/Park/GrammarAgreement";
import countryDestination from "../components/country/country-destination";
import HikeFilterSearchBox from "../components/Filters/hikes-filters/hike-filter-searchbox";
import HikeResultFilter from "../components/Filters/hikes-filters/hike-results-filter";

import banner_leaderboard from "../images/banners/leaderboard-motion.mp4";

import ParkRules from "../components/Park/ParkTemplate/ParkRules";
import ParkActivities from "../components/Park/ParkTemplate/Sidebar/ParkActivities";
import ParkWildlife from "../components/Park/ParkTemplate/Sidebar/ParkWildlife";
import ParkFlora from "../components/Park/ParkTemplate/Sidebar/ParkFloral";
import AffiliateLinks from "../components/Park/ParkTemplate/Sidebar/AffiliateLinks";
import WhenToGo from "../components/Park/ParkTemplate/Sidebar/WhenToGo";
import WhereToStay from "../components/Park/ParkTemplate/Sidebar/WhereToStay";
import ParkAccommodations from "../components/Park/ParkTemplate/ParkAccommodations";

export const Head = ({ data }: HeadProps<Queries.ParkTemplateQuery>) => {
  const park = data.contentfulPark;
  const parkHikesGallery = data.hikesGallery;
  const country = countryDestination(park?.countryCode);
  const pathname = `/${country}/${park?.slug}/`;

  const parkFullTitle = park?.fullTitle;
  const parkTitle = park?.title;

  return (
    <Meta
      title={`${parkTitle} > Guide & Randonnées`}
      description={`Retrouvez un guide complet sur le ${parkFullTitle} : Meilleures randonnées • quand partir • faune • flore • réglementation…`}
      lang={"fr"}
      media={`https:${parkHikesGallery.nodes[0]?.cover?.file?.url}`}
      pathname={pathname}
    ></Meta>
  );
};

export default function ParkTemplate({
  data,
  location,
}: PageProps<Queries.ParkTemplateQuery>) {
  // Filters
  const [selectedDifficulties, setSelectedDifficulties] = React.useState<
    string[]
  >([]);
  const [selectedDogStatus, setSelectedDogStatus] = React.useState<string[]>(
    []
  );
  const [selectedRanking, setSelectedRankings] = React.useState<string[]>([]);
  const [selectedDuration, setSelectedDuration] = React.useState<
    number | number[]
  >([0, 24]);
  const [selectedElevation, setSelectedElevation] = React.useState<
    number | number[]
  >([0, 2000]);
  const [selectedDistance, setSelectedDistance] = React.useState<
    number | number[]
  >([0, 50]);
  const [isURLParamChecked, setIsURLParamChecked] = React.useState(false);

  // Globals data variables
  const park = data.contentfulPark;
  const allParks = data.allContentfulPark;
  const parkHikesGallery = data.hikesGallery;
  const parkHikesList = data.hikesList;

  // Park variables section
  const parkFullTitle = park?.fullTitle;
  const parkTitle = park?.title;
  const parkSlug = park?.slug;
  const parkDescription = park?.description?.description ? (
    <p>{park.description.description}</p>
  ) : (
    ""
  );
  const parkOfficialWebsite = () => {
    if (park?.tourismOfficeUrl) {
      <p>
        <a
          href={validateURL(park.tourismOfficeUrl)}
          rel="nofollow noopener noreferrer"
          target="_blank"
        >
          → Voir le site de l'office du tourisme
        </a>
      </p>;
    }
    return null;
  };
  const parkLat = park?.location?.lat;
  const parkLng = park?.location?.lon;

  // Breadcrumb
  const country = countryDestination(park?.countryCode);
  const pathPark = `/${country}/${park?.slug}/`;
  const breadcrumbArray = [[pathPark, parkTitle]];

  // GrammarAgreement
  const parkgrammarAgreement = GrammarAgreement(park?.grammarAgreement);
  const parkGrammarAgreementOf = parkgrammarAgreement?.grammarAgreementOf;
  const parkGrammarAgreementAt = parkgrammarAgreement?.grammarAgreementAt;
  const parkGrammarAgreementIn = parkgrammarAgreement?.grammarAgreementIn;

  const parkRules = park?.rules?.rules;
  const parkActivities = park?.activities?.activities;
  const parkWildlife = park?.wildlife?.wildlife;
  const parkFlora = park?.flora?.flora;
  let parkMap: JSX.Element | null = null;

  // Sidebar
  const bnbAffiliateLink = park?.bnbAffiliationLink?.bnbAffiliationLink; // Affiliate Links
  const hotelAffiliateLink = park?.hotelAffiliationLink?.hotelAffiliationLink; // Affiliate Links
  const whenToGoContent = park?.whenToGo?.whenToGo; // When to go
  const whereToStayContent = park?.accomodations?.accomodations; // Where to stay

  // Create ParkDogs section with conditionals
  const ParkDogs = () => {
    if (park?.dogs?.dogs) {
      return (
        <>
          <b>Chiens :</b>
          <ReactMarkdown>{park.dogs?.dogs}</ReactMarkdown>
        </>
      );
    }
    return null;
  };

  // Create Static Map Image Section if lat and lon are available
  if (parkLat && parkLng) {
    // const [mapImage, setMapImage] = useState<JSX.Element | null>(null);

    // useEffect(() => {
    //   const fetchMapImage = async () => {
    const parkStaticImage = MapboxStaticImage({
      simpleMap: {
        lat: parkLat,
        lon: parkLng,
        zoom: 8
      },
      alt: `Carte de la réserve naturelle ${parkTitle}`,
      width: 600,
      height: 400,
    });
    //     setMapImage(image);
    //   };

    //   fetchMapImage();
    // }, [parkLat, parkLng]);

    parkMap = (
      <div className="position-relative">
        {/* <Button className='position-absolute top-50 start-50 translate-middle'>Déplier la carte</Button> */}
        <div className="parkmap-img">{parkStaticImage}</div>
      </div>
    );
  }

  // If there is no "GO TO" section available don't show the sidebar section.
  const GoToPark = () => {
    if (
      AffiliateLinks({ bnbAffiliateLink, hotelAffiliateLink }) ||
      WhenToGo({ whenToGoContent }) ||
      WhereToStay({ whereToStayContent })
    ) {
      return (
        <div className="sidebar-section">
          <div className="sidebar-header-section">
            <h2>
              Partir{" "}
              <small className="font-weight-medium">
                {parkGrammarAgreementAt} {parkTitle}
              </small>
            </h2>
          </div>

          <AffiliateLinks
            bnbAffiliateLink={bnbAffiliateLink}
            hotelAffiliateLink={hotelAffiliateLink}
          />

          <WhereToStay whereToStayContent={whereToStayContent} />

          <WhenToGo whenToGoContent={whenToGoContent} />
        </div>
      );
    }
    return null;
  };

  // Return the park content
  return (
    <Layout>
      <Container fluid>
        <Row className="banner-leaderboard">
          <Col sm="12" md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
            <ObfuscationLink
              encodeUrl={
                "https://chilowe.com/selection/micro-aventures-avec-guide/#ae223"
              }
            >
              <video autoPlay loop muted playsInline>
                <source src={banner_leaderboard} type="video/mp4"></source>
              </video>
            </ObfuscationLink>
          </Col>
        </Row>
      </Container>
      <Container className="pt-2">
        <Breadcrumb location={location} itemsArray={breadcrumbArray} />
        <div className="text-center">
          <h1>{parkFullTitle}</h1>
        </div>
      </Container>

      <Container>
        <Row>
          <Col className="pb-4">
            <ParkGallery
              dataSlider={parkHikesList}
              dataGallery={parkHikesGallery}
            />
          </Col>
        </Row>
      </Container>

      <Container className="container-md">
        <Row>
          <Col xs="12" md="8" lg="9">
            <Row className="mb-3">
              <Col className="text-center" md="12" lg="6">
                {parkMap}
              </Col>
              <Col md="12" lg="6">
                <div className="green">
                  {parkDescription}

                  <ParkDogs />

                  {parkOfficialWebsite()}
                </div>
              </Col>
            </Row>

            <h2 className="mb-3">
              Randonnées {parkGrammarAgreementIn} {parkTitle}
            </h2>
            <HikeFilterSearchBox
              location={location}
              hikes={parkHikesList}
              selectedDifficulties={selectedDifficulties}
              selectedDogStatus={selectedDogStatus}
              selectedRanking={selectedRanking}
              selectedDuration={selectedDuration}
              selectedElevation={selectedElevation}
              selectedDistance={selectedDistance}
              setSelectedDifficulties={setSelectedDifficulties}
              setSelectedDogStatus={setSelectedDogStatus}
              setSelectedRankings={setSelectedRankings}
              setSelectedDuration={setSelectedDuration}
              setSelectedElevation={setSelectedElevation}
              setSelectedDistance={setSelectedDistance}
              isURLParamChecked={isURLParamChecked}
            />
            <Row>
              <HikeResultFilter
                location={location}
                hikes={parkHikesList}
                setSelectedDifficulties={setSelectedDifficulties}
                setSelectedDogStatus={setSelectedDogStatus}
                setSelectedRankings={setSelectedRankings}
                setSelectedDuration={setSelectedDuration}
                setSelectedElevation={setSelectedElevation}
                setSelectedDistance={setSelectedDistance}
                setIsURLParamChecked={setIsURLParamChecked}
                isURLParamChecked={isURLParamChecked}
              />
            </Row>

            <ParkAccommodations
              slug={parkSlug}
              parkFullTitle={parkFullTitle}
              parkTitle={parkTitle}
              parkGrammarAgreementOf={parkGrammarAgreementOf}
            />

            <ParkRules
              parkTitle={parkTitle}
              parkRules={parkRules}
              grammarAgreementOf={parkGrammarAgreementOf}
            />
          </Col>

          <Col xs="12" md="4" lg="3">
            <GoToPark />

            <ParkActivities activities={parkActivities} />

            <ParkWildlife
              wildlife={parkWildlife}
              parkTitle={parkTitle}
              grammarAgreementOf={parkGrammarAgreementOf}
            />

            <ParkFlora
              flora={parkFlora}
              parkTitle={parkTitle}
              grammarAgreementOf={parkGrammarAgreementOf}
            />
          </Col>
        </Row>
      </Container>

      <Container className="py-5">
        <h2>Découvrir d'autres réserves naturelles</h2>
        <Link to={`/randonnees/`}>
          Voir tous les parcs naturels où randonner ‣
        </Link>
        <ParksCarousel data={allParks} />
      </Container>
    </Layout>
  );
}

export const parkTemplateQuery = graphql`
  query ParkTemplate($slug: String!) {
    contentfulPark(slug: { eq: $slug }) {
      ...OnePark
    }
    allContentfulPark(filter: { node_locale: { eq: "fr" } }, limit: 10) {
      ...AllParksList
    }
    hikesGallery: allContentfulHike(
      filter: { park: { slug: { eq: $slug } }, node_locale: { eq: "fr" } }
      limit: 30
      sort: { rank: ASC }
    ) {
      ...HikesGallery
    }
    hikesList: allContentfulHike(
      filter: { park: { slug: { eq: $slug } }, node_locale: { eq: "fr" } }
      limit: 100
      sort: { rank: ASC }
    ) {
      ...HikesFilter
    }
  }
`;
