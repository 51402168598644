import React from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import floral_icon from "../../../../images/icons/icon_floral.svg";


interface Props {
    flora: string | null | undefined,
    grammarAgreementOf: string | null | undefined,
    parkTitle: string | null | undefined
}

  // Create sidebar Flora section
  export default function ParkFlora(props: Props) {
    if (props.flora) {
      return (
        <div className="sidebar-section">
          <div className="sidebar-content-section">
            <div className="d-flex mb-2">
              <img
                src={floral_icon}
                alt="Quelle flore est présente dans ce parc"
                className="icon d-inline-flex me-2"
              />
              <h3 className="d-inline-flex align-items-center mb-0">
                Flore {props.grammarAgreementOf} {props.parkTitle}
              </h3>
            </div>
            <ReactMarkdown>{props.flora}</ReactMarkdown>
          </div>
        </div>
      );
    }
    return null;
  };