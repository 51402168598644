import React from "react";
import ParkCard from "./ParkCard";
import Carousel from "../UI/Carousel/Carousel";

// https://react-slick.neostack.com/docs/example/

interface Props {
  data: Queries.AllParksListFragment
}
export default class ParksCarousel extends React.Component<Props> {

  constructor(props: Props) {
    super(props)
  }

  render() {
    const allParks = this.props.data;

    if(allParks) {
      const listOfCards = allParks.nodes?.flatMap((park) => park ? [park] : []) // Filter null elements
      .map((park) => (
        <ParkCard park={park} key={park?.id} />
      ))
      
      const carouselSettings = {
        responsive: {
          breakpoint1024: {
              slidesToShow: 2,
              slidesToScroll: 2
          },
          breakpoint768: {
              slidesToShow: 1.7,
              slidesToScroll: 1,
              arrows: false
          },
          breakpoint480: {
              slidesToShow: 1.4,
              slidesToScroll: 1,
              arrows: false
          }
        },
        className: "center",
        centerPadding: "60px",
        slidesToShow: 4.2,
        slidesToScroll: 3,
        infinite: false,
      }
  
      const carouselProps = { listOfCards, carouselSettings }
  
      return (
          <Carousel {...carouselProps} />
      )
    }
    return null;
  
  }

}  

