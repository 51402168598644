import React from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import where_to_stay_icon from '../../../../images/icons/icon_where_to_go.svg';

// Create sidebar park accomodations section
export default function WhereToStay(props: { whereToStayContent: string | null | undefined }) {
  if (props.whereToStayContent) {
    return (
      <div className="sidebar-content-section">
        <div className="d-flex mb-2">
          <img
            src={where_to_stay_icon}
            alt="Où partir en vacances pour randonner"
            className="icon d-inline-flex me-2"
          />
          <h3 className="d-inline-flex align-items-center mb-0">Où loger ?</h3>
        </div>
        <ReactMarkdown>{props.whereToStayContent}</ReactMarkdown>
      </div>
    );
  }
  return null;
};
