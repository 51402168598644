import React from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import calendar_icon from "../../../../images/icons/icon_calendar.svg";

export default function WhenToGo(props: {whenToGoContent: string | null | undefined} ) {
  if (props.whenToGoContent) {
    return (
      <div className="sidebar-content-section">
        <div className="d-flex mb-2">
          <img
            src={calendar_icon}
            alt="Quand partir en vacances pour randonner dans ce parc"
            className="icon d-inline-flex me-2"
          />
          <h3 className="d-inline-flex align-items-center mb-0">
            Quand partir ?
          </h3>
        </div>
        <ReactMarkdown>{props.whenToGoContent}</ReactMarkdown>
      </div>
    );
  }
  return null;
}
